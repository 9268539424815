const cfgMap = new Map();
const CSRF_TOKEN = '';
const DEBUG_MODE = 'debug';
const FEATURE_TOGGLE = 'feature-toggle-store';
cfgMap.set(DEBUG_MODE, false);

const getConfig = key => {
  const isDebug = cfgMap.get(DEBUG_MODE);

  if (isDebug) {
    if (cfgMap.get(key)) {
      console.log(`getConfig: key is ${key}, value is ${cfgMap.get(key)}`);
    } else {
      console.log(`getConfig: ${key} is missing`);
    }
  }

  return cfgMap.get(key);
};

const addConfig = (key, value) => {
  const isDebug = cfgMap.get(DEBUG_MODE);

  if (isDebug) {
    if (cfgMap.get(key)) {
      console.log(`addConfig: update key is ${key}, value is ${value}`);
    } else {
      console.log(`addConfig: new key is ${key}, value is ${value}`);
    }
  }
  cfgMap.set(key, value);
};

const getCsrfToken = () => {
  return getConfig(CSRF_TOKEN);
};

const setCsrfToken = token => {
  addConfig(CSRF_TOKEN, token);
};

const setDebug = debug => {
  addConfig(DEBUG_MODE, debug);
};

const getFeatureToggle = (...args) => {
  const featureToggles = getConfig(FEATURE_TOGGLE) || {};
  if (args.length === 0) {
    // assume you want all
    return {
      ...featureToggles,
    };
  } else if (args.length === 1) {
    // assume it's a string array or a standalone string
    const arg = args[0];
    if (Array.isArray(arg) && arg.length > 0) {
      const values = {};
      arg.forEach(key => {
        values[key] = featureToggles[key];
      });
      return values;
    } else {
      const stringKey = String(arg);
      return featureToggles[stringKey];
    }
  } else {
    // if (args.length > 1)
    // assume paramters are all string keys
    const values = {};
    const total = args.length;
    for (let i = 0; i < total; i++) {
      values[args[i]] = featureToggles[args[i]];
    }
    return values;
  }
};
const updateFeatureToggle = (...args) => {
  // different ways // otherwise not valid args
  if (args.length === 1) {
    const featureToggles = { ...getConfig(FEATURE_TOGGLE) };
    // assume you pass an object to update or an array with key/value
    const updateObject = args[0];
    if (Array.isArray(updateObject)) {
      updateObject.forEach(item => {
        featureToggles[item.feature || item.key] = item.hasOwnProperty('isEnable')
          ? item.isEnable
          : item.value;
      });
    } else if (typeof updateObject === 'object') {
      Object.keys(updateObject).forEach(key => {
        featureToggles[key] = updateObject[key];
      });
    }
    addConfig(FEATURE_TOGGLE, featureToggles);
  } else if (args.length >= 2) {
    const featureToggles = { ...getConfig(FEATURE_TOGGLE) };
    // assume first is key, second is value
    featureToggles[args[0]] = args[1];
    addConfig(FEATURE_TOGGLE, featureToggles);
  }
};

const getLanguage = () => {
  return getConfig('ui-language');
};

const setLanguage = language => {
  addConfig('ui-language', language);
};

export default {
  addConfig,
  getConfig,
  getCsrfToken,
  setCsrfToken,
  setDebug,
  getFeatureToggle,
  updateFeatureToggle,
  getLanguage,
  setLanguage,
};
